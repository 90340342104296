@font-face {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
  /* ../../../../assets/Tohama/Tahoma_Regular_font.ttf */
  src: url("../../assets/Tahoma/Tahoma_Regular_font.ttf")
    format("truetype");
  /* Update the font file path and format if necessary */
  /* Add more src declarations for different font formats if needed */
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.btn-warning {
  background-color: #2d4b64 !important;
  border: #2d4b64 !important;
  color: #fff !important;
  font-weight: 500;
  display: block;
  margin: auto;
  border-radius: 10px;
}

.form-control {
  background-color: #f2f2f2;
  color: #000;
}

.form-check-input[type="checkbox"] {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #2d4b64;
  border-color: #2d4b64;
}

.btn-loginAs {
  background-color: #707076 !important;
  color: #fff !important;
  border: #4a88ca !important;
}

.selected {
  background-color: #2d4b64 !important;
  color: white !important;
  /* Add any other desired styles */
}

.logo_style {
  width: 300px !important;
}

@media (max-width: 767px) {
  .logo_style {
    width: 150px !important;
  }
  .mbl-text {
    font-size: 13px !important;
  }
}
