#reviewSec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.reviewsection {
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  padding: 20px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  height: auto;
  /* border: 2px solid #bfbfbf; */
}

.reviewImglogo {
  width: 80px;
  height: auto;
  margin-bottom: 15px;
  cursor: pointer;
}

/* .reviewImg:hover,
.reviewImg.selected {
  border: 2px solid #1f3c76;
  border-radius: 50px;
} */

.reviewImg:hover,
.reviewImg.selected {
  border: 2px solid #1f3c76;
  /* border-radius: 50px; */
  border-radius: 50px;
  transform: scale(1.5);
  transition: o.9s;
}

.reviewImg:hover[src$="Happy"] {
  border-color: green;
  /* Border color for the first image on hover */
}

.reviewImg:hover[src$="Dontknow"] {
  border-color: yellow;
  /* Border color for the second image on hover */
}

.reviewImg:hover[src$="Sad"] {
  border-color: red;
  /* Border color for the third image on hover */
}

#result .apexcharts-legend-text {
  display: none;
}

#result .apexcharts-legend-marker {
  display: none;
}

.reviewImg {
  /* width: 80px;
  height: auto; */
  border: 2px solid #fff;
  margin-bottom: 15px;
  cursor: pointer;
}

.mob-flex {
  display: flex;
}

.thankHeading {
  color: green;
  font-size: 30px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
}

#mobFlex > div {
  cursor: pointer;
  padding: 10px;
}

#mobFlex > div:hover {
  /* Add hover styles */
  background-color: lightgray;
  /* Adjust the background color as desired */
}

#mobFlex > div.selected {
  /* Add selected styles */
  background-color: gray;
  /* Adjust the background color as desired */
  color: white;
  /* Adjust the text color as desired */
}

.mob-flex > .optionStyle {
  border: 1px solid #d7d2d2 !important;
  color: #000;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  margin-left: 5px;

  margin-bottom: 2px;
}

@media screen and (max-width: 500px) {
  .mob-flex {
    display: grid;
  }
  .reviewImg:hover,
  .reviewImg.selected {
    border: 3px solid #1f3c76;
    transform: scale(1.2);
    transition: o.9s;
  }
  .mob-flex > .optionStyle {
    border: 1px solid #d7d2d2 !important;
    color: #000 !important;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    width: 220px;
    margin-bottom: 10px;
  }

  .mob-flex > .selected {
    color: white !important;
  }
  .reviewImg {
    border: 2px solid #fff;
    margin-bottom: 50px;
    cursor: pointer;
    width: 100px;
  }
}
