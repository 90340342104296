/* ADMIN MAIN CSS  file style START*/

/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap'); */

/* @font-face {
  font-family: "Epilogue";
  src: url("../../../../assets/Epilogue/Epilogue-VariableFont_wght.ttf")
    format("truetype");
} */

/* body {
    font-family: 'CustomFont', Arial, sans-serif;
  } */
@font-face {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
  /* ../../../../assets/Tohama/Tahoma_Regular_font.ttf */
  src: url("./assets/Tahoma/Tahoma_Regular_font.ttf") format("truetype");
}

a {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.outerWrapperSearchBox {
  width: 50%;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: #2d4b64;
}

body {
  overflow-x: hidden;
  line-height: 26px;
  font-size: 16px;
  height: 100%;
  font-weight: 400;
  overflow-y: scroll;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.bgPurple {
  background-image: url("./assets/images/icons/2.png");
  background-size: cover;
}

:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 500;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
  color: #000;
}

p {
  font-size: 15px;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

a {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

small {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.geJtFr {
  background-color: #707076 !important;
}

.fwUHLW div:first-child {
  width: 100px !important;
}

.inTGaN {
  min-width: 197px !important;
}

::placeholder {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

span {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

button {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.form-control {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.form-select {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
  font-weight: 400 !important;
}

label {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.gridjs *,
.gridjs :after,
.gridjs :before {
  box-sizing: border-box;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.IconSearchInReport {
  position: absolute;
  top: 41px;
  left: 0;
}

.dashboard {
  font-size: 28px !important;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.apexcharts-legend-text {
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.dashboardinput {
  width: 100% !important;
  background: #f9fbff;
  border: #f6f6fb;
  padding: 8px;
  border-radius: 10px;
  padding-left: 30px;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.searchIcon {
  position: relative;
  top: -30px;
  color: #627b87;
  left: 6px;
}

.dashboardinput::placeholder {
  color: #627b87;
  padding-right: 20px !important;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.maxsizeExportButton {
  width: 200px !important;
}

.apexcharts-legend-text {
  font-size: 15px !important;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
  border-radius: 10px;
  margin-bottom: 20px;
}

.border-right {
  border-right: 1px solid #c7c9ca;
}

.bg-border {
  border: 1px solid #d7d2d2;
  border-radius: 50px;
  padding: 3px 10px;
  text-align: center;
  margin-bottom: 10px;
}

.btn-chart {
  font-weight: 500;
  border-radius: 20px;
  border: none;
  padding: 0px 13px;
  /* margin-left: 10px; */
  font-size: 12px;

  background-color: #fff;
}

.btn-chart.active {
  background-color: #2d4b64 !important;
  border: #2d4b64;
  border-radius: 20px;
  color: #fff !important;
  padding: 0px 13px;
  font-size: 12px;
  /* margin-left: 10px; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.chartContent h6 {
  font-size: 15px;
  font-weight: 500;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.map-container {
  width: 100%;
  height: 100% !important;
  flex-grow: 1;
  position: relative;
}

.mbl-top {
  position: relative;
  width: 100% !important;
  height: 299px !important;
}

.filter-btn {
  display: flex;
  height: 40px;
  margin-top: 0px;
  font-weight: 500;
  background: transparent;
}

#filer .form-control {
  margin-left: 5px;
  margin-bottom: 10px;
}

#filer .form-group {
  margin-left: 5px;
  margin-bottom: 10px;
}

.chartContent p {
  font-size: 14px;
}

.dashboardIcon {
  margin-left: -43px;
  margin-top: 0;
  color: #627b87;
}

#dropdown-filters {
  background-color: white !important;
  color: #959595 !important;
  box-shadow: none !important;
}

#dropdown-filters::after {
  display: none !important;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.small-chart {
  width: 400px;
  height: auto;
}

.card-height {
  height: 350px !important;
}

.card-height-btm {
  /* height: 400px !important; */
}

.cursor-pointer {
  cursor: pointer !important;
}

/* //=========Search style */
.searchBarDrop {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid rgba(49, 55, 67, 0.2);
  box-shadow: 0px 20px 41px -19px #a8adb8;
  margin-top: 5px;
}

.dropBorder {
  top: -45px !important;
  position: relative;
  background: aliceblue;
  border-bottom: 1px solid rgb(178 181 214);
  margin-bottom: 0px;
  padding-bottom: 14px;
  width: 49.2%;
  z-index: 999;
  cursor: pointer;
  border-radius: 9px;
}

/* .dropBorder:hover{
  background: #68687890;
} */

.bgligt0orange {
  background: #fbf4e6;
  padding: 5px;
  border-radius: 100px;
  color: rgba(214, 144, 10, 1) !important;
}

.header {
  position: relative;
}

.searchBarDrop {
  position: absolute;
  top: 96%;
  left: 10px;
  background-color: white;
  width: auto;
  padding: 10px;
  border: 1px solid #ccc !important;
  border-top: none;
  border-radius: 13px;
  z-index: 999;
  padding: 12px;
}

.searchBarDrop .dropBorder img {
  height: 65px;
}

.searchBarDrop .dropBorder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropHeadin {
  font-size: 15px;
  padding-bottom: 0px;
  color: #313743;
  margin: 0;
}

.detailsIcon {
  position: relative;
  top: -20px;
  left: 11px;
}

.detailsIconStyle {
  font-size: 25px !important;
}

@media (max-width: 768px) {
  .small-chart {
    width: 100% !important;
    height: 100% !important;
  }

  .IconSearchInReport {
    top: 50px;
  }

  .mbl-style {
    width: 130px !important;
    font-size: 12px !important;
  }

  .card-height {
    height: 100% !important;
  }

  /* .card-height-btm {
    height: 100% !important;
  } */


  .map-container {
    width: 100%;
    height: 100% !important;
    flex-grow: 1;
    position: relative;
  }

  .mbl-top {
    position: relative;
  }

  .dropBorder {
    width: 100%;
  }
}

/* ADMIN MAIN CSS  file style END*/

/* Report file style START*/
.form-control:focus {
  box-shadow: none !important;
  border-color: #2d4b64;
}

@media (max-width: 1123px) {
  .btn-secondary {
    font-size: 16px !important;
  }
}

@media (max-width: 996px) {
  .btn-secondary {
    font-size: 15px !important;
  }
}

@media (max-width: 767px) {
  .form-control {
    margin-bottom: 10px !important;
  }
}

/* Report file style END*/

/* SALES file style START*/
.image {
  background: linear-gradient(201.18deg, #d3ffe7 3.14%, #effff6 86.04%);
  padding: 20px;
  border-radius: 100px;
  width: 70px;
  height: 70px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
}

.green {
  color: #00ac4f;
  font-weight: 500;
  font-size: 14px;
}

.red {
  color: red;
  font-weight: 500;
  font-size: 14px;
}

.monthSales {
  color: #292d32;
  font-size: 14px;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  background-color: #fff !important;
  /* border-bottom: 1px solid #eee; */
}

.bgcards {
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  padding: 10px;
  border-radius: 10px;
}

.bgwhite {
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  margin: 4px;
}

.topSales {
  color: #acacac;
  font-size: 14px;
}

/* SETTING file style */
.review-drag {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  transition: 0.5s;
  margin-top: 8px;
}

.review-drag img {
  width: 30x;
  margin-bottom: 10px;
}

.review-drag h5 {
  font-size: 1rem;
  font-weight: 500;
  margin: 10px 0;
}

.review-drag h6 {
  font-size: 0.9rem;
  color: #64748b;
  font-weight: 400;
}

.review-drag input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

#userSetting .form-control {
  border-radius: 10px;
}

.form-control:focus {
  border-color: #2d4b64;
}

#userSetting .form-select {
  border-radius: 10px;
  color: #000;
}

.form-select:focus {
  box-shadow: none;
  border-color: #2d4b64;
}

.uploadbgIcon {
  background-color: #2d4b64;
  color: #fff;
  padding: 8px;
  border-radius: 100px;
  width: 11px;
  height: 11px;
}

.whiteShadow {
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 20px;
  display: block;
  margin-bottom: 15px;
}

.pink {
  color: #2d4b64;
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #2d4b64;
  border-color: var(--bs-nav-tabs-link-active-border-color);
  border-radius: 50px;
  font-size: 15px;
  font-weight: 500;
}

.nav-tabs .nav-link {
  color: #000;
  font-weight: 500;
  margin-right: 10px;
}

.modal-header {
  border-bottom: none;
  padding: 10px 20px;
  padding-top: 15px;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  font-size: 20px;
}

.modalwhite {
  border: 1px solid #d7d2d2;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}

#popupSelect .form-select {
  border: 2px solid #2d4b64 !important;
}

#popupSelect {
  max-height: 400px !important;
  overflow: auto;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
  border-radius: 50px;
  margin-right: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

/* STICKER file style START*/

/* .vertical-text {
  position: relative;
  top: -230px;
  left: 183px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 20px;
  font-weight: bold;
  color: #333;
  padding: 0px;
  text-align: center;
} */

/* SUBSCRIPTION/SHOP file style START*/

.sub-cards {
  border-radius: 15px;
  padding: 10px;
  border: 2px solid #2d4b64;
  box-shadow: -2px 2px 7px 0px #8a8a8a;
  min-height: 210px !important;
  min-width: 100% !important;
  position: relative;
}

.sub-cards:hover {
  border-radius: 15px;
  padding: 10px;
  background-color: #dae9ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.editButton {
  color: #2d4b64 !important;
  justify-content: end;
  align-items: end;
  display: flex;
}

.editButton:hover {
  color: #2d4b64 !important;
  justify-content: end;
  align-items: end;
  display: flex;
}

.deleteButton {
  color: red !important;
  justify-content: start;
  align-items: start;
  display: flex;
}

.deleteButton:hover {
  color: red !important;
  justify-content: start;
  align-items: start;
  display: flex;
}

.modal .form-select {
  border: 2px solid #2d4b64;
}

.css-1p3m7a8-multiValue {
  border: 1px solid #ff3f6d;
  border-radius: 10px;
  background-color: #fff !important;
}

.css-13cymwt-control {
  border-radius: 10px !important;
}

.blueHead {
  color: #2d4b64;
}

/* SUPPORT file style START*/
.message-area {
  height: 100vh;
  overflow: hidden;
  padding: 30px 0;
  background: #f5f5f5;
}

.bg-white {
  background-color: #d6dce5 !important;
  /* height: 100vh; */
}

.chat-area {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: 0.3rem;
  height: 90vh;
  overflow: hidden;
  min-height: calc(100% - 1rem);
}

.chatlist {
  outline: 0;
  height: 100%;
  overflow: hidden;
  padding-bottom: 30px !important;
}

.chat-area .modal-content {
  border: none;
  border-radius: 0;
  outline: 0;
  height: 100%;
}

.chat-area .modal-dialog-scrollable {
  height: 100% !important;
}

.chatbox {
  width: auto;
  overflow: hidden;
  height: 100%;
}

.chatbox .modal-dialog,
.chatlist .modal-dialog {
  max-width: 550px;
  margin: 0;
}

.msg-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-area .form-control {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #222;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chat-area .form-control:focus {
  outline: 0;
  box-shadow: inherit;
}

a.add img {
  height: 36px;
}

.chat-area .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  align-items: center;
  justify-content: space-between;
  flex-wrap: inherit;
}

.chat-area .nav-tabs .nav-item {
  width: 100%;
}

.chat-area .nav-tabs .nav-link {
  width: 100%;
  color: #262626;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.chat-area .nav-tabs .nav-item.show .nav-link,
.chat-area .nav-tabs .nav-link.active {
  color: #222;
  background-color: #fff;
  border-color: transparent transparent #000;
}

.chat-area .nav-tabs .nav-link:focus,
.chat-area .nav-tabs .nav-link:hover {
  border-color: transparent transparent #000;
  isolation: isolate;
}

.msg-search h4 {
  color: #1e293b;
}

.chat-list h3 {
  color: #334155;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.chat-list p {
  color: #94a3b8;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.chat-list a.d-flex {
  margin-bottom: 5px;
  position: relative;
  text-decoration: none;
  padding: 8px 6px;
  border-radius: 6px;
}

.chat-list .active {
  display: block;
  content: "";
  clear: both;
  position: absolute;
  bottom: 3px;
  left: 34px;
  height: 12px;
  width: 12px;
  background: #00db75;
  border-radius: 50%;
  border: 2px solid #fff;
}

.msg-head h3 {
  color: #1e293b;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}

.msg-head p {
  color: #343434;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.moreoption {
  display: flex;
  align-items: center;
  justify-content: end;
}

.moreoption .navbar {
  padding: 0;
}

.moreoption li .nav-link {
  color: #222;
  font-size: 16px;
}

.moreoption .dropdown-toggle::after {
  display: none;
}

.msg-body ul {
  overflow: hidden;
}

.msg-body ul li {
  list-style: none;
  margin: 15px 0;
}

.msg-body ul li.sender {
  display: block;
  width: 100%;
  position: relative;
}

.msg-body ul li.sender:before {
  display: block;
  clear: both;
  content: "";
  position: absolute;
  top: -6px;
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 15px 12px;
  border-color: transparent transparent #c9baba transparent;
  -webkit-transform: rotate(-37deg);
  -ms-transform: rotate(-37deg);
  transform: rotate(-37deg);
}

.msg-body ul li.sender p {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: #e7e6e6;
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 0;
}

.msg-body ul li.sender p b {
  display: block;
  color: #180660;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.msg-body ul li.repaly {
  display: block;
  width: 100%;
  text-align: right;
  position: relative;
}

.msg-body ul li.repaly p {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: #fff;
  display: inline-block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 0;
  position: absolute;
  right: 55px;
  top: -6px;
}

.msg-body ul li.repaly p b {
  display: block;
  color: #2d4b64;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.msg-body ul li.repaly:after {
  display: block;
  content: "";
  clear: both;
}

.time {
  display: block;
  color: #94a3b8;
  font-size: 12px;
  line-height: 2.5;
  font-weight: 400;
  margin-left: 5px;
}

li.repaly .time {
  margin-right: 5px;
}

.divider {
  position: relative;
  z-index: 1;
  text-align: center;
}

ul {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.msg-body h6 {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: #222;
  background: #fff;
  display: inline-block;
  padding: 3px 11px;
  border-radius: 6px;
  margin-bottom: 0;
}

.divider:after {
  display: block;
  content: "";
  clear: both;
  position: absolute;
  top: 12px;
  left: 0;
  border-top: 1px solid #ebebeb;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.send-box {
  padding: 10px 10px;
}

.send-box form {
  align-items: center;
}

.send-box .form-control {
  display: block;
  width: 100%;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #222;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 10px;
}

.sendicon {
  display: block;
  color: #94a3b8;
}

.send-btns .button-wrapper {
  position: relative;
  width: 125px;
  height: auto;
  text-align: left;
  margin: 0 auto;
  display: block;
  background: #f6f7fa;
  border-radius: 3px;
  padding: 5px 15px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  overflow: hidden;
}

.btn-send {
  position: relative;
  right: 6%;
}

.send-btns .button-wrapper span.label {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: #343945;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 13px;
}

#upload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.send-btns .attach .form-control {
  display: inline-block;
  width: 120px;
  height: auto;
  padding: 5px 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #343945;
  background-color: #f6f7fa;
  background-clip: padding-box;
  border: 1px solid #f6f7fa;
  border-radius: 3px;
  margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
  margin-right: 5px;
}

.button-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
}

button:focus {
  outline: 0;
}

.add-apoint {
  display: inline-block;
  margin-left: 5px;
}

.add-apoint a {
  text-decoration: none;
  background: #f6f7fa;
  border-radius: 8px;
  padding: 8px 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  color: #343945;
}

.add-apoint a svg {
  margin-right: 5px;
}

.chat-icon {
  display: none;
}

.closess i {
  display: none;
}

.chathover:hover {
  background-color: #81818d83;
}

/* .supportHeight {
  height: 100vh;
} */

.imgCrossIcon {
  position: relative;
  left: -10px;
  top: -20px;
  background: red;
  border-radius: 50%;
  padding: 3px;
  color: white;
  cursor: pointer;
}

.qr-logo-img {
  height: 90px !important;
  object-fit: contain;
  mix-blend-mode: color-burn
}

@media (max-width: 992px) {
  .btn-send {
    position: relative;
    right: 13%;
  }
}

@media (max-width: 767px) {
  /* .supportHeight {
    height: auto !important;
  } */

  .chat-icon {
    display: block;
    margin-right: 5px;
  }

  .chatlist {
    width: 100%;
  }

  .chatbox {
    width: 100%;
    right: 0;
    background: #fff;
    transition: all 0.5s ease;
    border-left: none;
  }

  .showbox {
    left: 0 !important;
    transition: all 0.5s ease;
  }

  .msg-head h3 {
    font-size: 14px;
  }

  .msg-head p {
    font-size: 12px;
  }

  .msg-head .flex-shrink-0 img {
    height: 30px;
  }

  .btn-send {
    position: relative;
    right: 12%;
  }

  .send-box .form-control {
    width: 100%;
  }

  .chat-list h3 {
    font-size: 14px;
  }

  .chat-list p {
    font-size: 12px;
  }

  .msg-body ul li.sender p {
    font-size: 13px;
    padding: 8px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .msg-body ul li.repaly p {
    font-size: 13px;
    padding: 8px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

#chatbox #dropdown-basic {
  background-color: #fff !important;
  border: none !important;
  color: #94a3b8 !important;
  box-shadow: none !important;
}

.modal-dialog-scrollable .modal-content {
  max-height: 60vh;
  min-height: 623px;
  overflow-y: auto;
}

.MinHeight {
  min-height: 443px !important;
}

#chatbox .modal-dialog-scrollable .modal-content::-webkit-scrollbar {
  width: 12px;
}

#chatbox .modal-dialog-scrollable .modal-content::-webkit-scrollbar-track {
  background: #e7e7e7;
}

#chatbox .modal-dialog-scrollable .modal-content::-webkit-scrollbar-thumb {
  background-color: #707076;
  border-radius: 20px;
  border: 3px solid #e7e7e7;
}











.ZhWIp::-webkit-scrollbar {
  width: 12px;
}

.ZhWIp::-webkit-scrollbar-track {
  background: #e7e7e7;
}

.ZhWIp::-webkit-scrollbar-thumb {
  background-color: #707076;
  border-radius: 20px;
  border: 3px solid #e7e7e7;
}

/* for body */

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #e7e7e7;
}

body::-webkit-scrollbar-thumb {
  background-color: #707076;
  border-radius: 20px;
  border: 3px solid #e7e7e7;
}

/* end */

.heDaAt {
  color: #000 !important;
}

/* for table */
.dlJOTa {
  border-radius: 6px !important;
}

.outerWrapperTable {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 6px;
}

.dlJOTa::-webkit-scrollbar {
  width: 12px;
}

.dlJOTa::-webkit-scrollbar-track {
  background: #e7e7e7;
}

.dlJOTa::-webkit-scrollbar-thumb {
  background-color: #707076;
  border-radius: 20px;
  border: 3px solid #e7e7e7;
}

/* end */


/* for card */

.card-height::-webkit-scrollbar {
  width: 12px;
}

.card-height::-webkit-scrollbar-track {
  background: #e7e7e7;
}

.card-height::-webkit-scrollbar-thumb {
  background-color: #707076;
  border-radius: 20px;
  border: 3px solid #e7e7e7;
}

/* end */

#chatbox .modal-dialog-scrollable .modal-body {
  overflow-y: visible;
  /* padding-right: 10px; */
}

.chaticon {
  color: #94a3b8;
}

.searchicon {
  position: relative;
  position: absolute;
  left: 10px;
  top: 11px;
  color: #94a3b8;
}

.searchField::placeholder {
  padding-left: 20px;
}

#chatbox .searchField {
  border-radius: 10px !important;
  border-color: #94a3b8;
  background-color: transparent !important;
}

.status {
  font-weight: 500;
  font-size: 14px;
  color: #94a3b8;
}

.textArea {
  height: 100px;
}

.answer {
  position: relative;
  top: 39px;
  left: 21px;
  border-bottom: 2px solid #e7e6e6;
  width: 90%;
  padding-bottom: 4px;
  color: #938f8f;
  font-weight: 400;
}

/* DATATOUCHPOINT file style START*/
.text-success {
  border: 1px solid #16c098 !important;
  color: #008767 !important;
  background: #16c09829;
  border-radius: 10px;
  padding: 4px;
  font-size: 13px;
  font-weight: 500;
}

.text-danger {
  border: 1px solid red !important;
  color: red !important;
  background: #ff000021;
  border-radius: 10px;
  padding: 4px;
  font-size: 13px;
  font-weight: 500;
}

.btn-secondary {
  background-color: #fff !important;
  border: 1px solid #d0d5dd !important;
  color: #344054 !important;
  font-weight: 500;
  padding: 6px 15px;
  height: 40px;
}

.usersearchInCountriesAndCity {
  position: relative;
  bottom: 32px;
  left: 20px;
  color: #627b87;
}

.outerWrapperDataTableInCountries .cNBXMv {
  max-width: 100% !important;
}

.joCucS svg {
  right: -11px !important;
  top: 2px !important;
}

.iikmgS svg {
  right: -11px !important;
  top: 2px !important;
}

.btn-secondary:hover {
  background-color: #fff !important;
  border: 1px solid #d0d5dd !important;
  color: #344054 !important;
  font-weight: 500;
}

.btn-secondary .btnIcon {
  background-color: #2d4b64 !important;
  color: #fff !important;
  padding: 2px;
  border-radius: 100px;
  margin-right: 8px;
  position: relative;
  top: 4px;
}

/* USER/COMPANY + VIEW file style START*/

.btn-primary {
  /* background-color: #2d4b64 !important;
  border: #2d4b64 !important;
  color: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  background-color: #2d4b64 !important;
  border: #2d4b64 !important;
  color: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 8px 15px;
  border-radius: 10px;
  height: 40px;
}

.gripjs-container {
  table-layout: fixed;
  width: 100%;
}

.gripjs-container th {
  width: calc(100% / 8);
}

.gripjs-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gripjs-container th,
.gripjs-container td {
  width: calc(100% / 8);
}

.gridjs-pagination .gridjs-pages button {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  margin-left: 10px;
  color: #404b52;
}

.usersearch {
  position: relative;
  bottom: 34px;
  left: 10px;
  color: #627b87;
}

#userSearch::placeholder {
  padding-left: 10px !important;
  font-size: 14px;
}

.kSDYzA {
  text-wrap: nowrap !important;
  overflow: visible !important;
}

.kXtZIP {
  overflow: unset !important;
  white-space: unset !important;
  text-overflow: unset !important;
}

.QRImage {
  mix-blend-mode: color-burn;
}

.LoadingButton {
  background-color: #2d4b64 !important;
  border: #2d4b64 !important;
  color: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  padding: 8px 15px !important;
  border-radius: 10px !important;
  height: 40px !important;
  width: fit-content !important;
}

select {
  word-wrap: normal !important;
  padding: 4px 7px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.ImageInSetting {
  width: 220px;
  height: 83px;
  object-fit: fill;
}

.logincontent {
  padding: 20px;
  border-radius: 13px;
  background-color: #9393932e;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.cVuHJw {
  font-size: 16px !important;
  color: white !important;
}

.outerWrapperContainer {
  background-color: #F8F8F8;
}

.Language {
  background-color: lightblue;
  color: white;
  padding: 6px 10px;
  font-size: 13px;
  border-radius: 5px;
  font-weight: 700;
}

.bg-grey {
  background-color: #f9fbff;
  padding: 8px 12px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 10px;
  justify-content: center;
}

.selectFeature {
  color: #7e7e7e;
  font-size: 14px;
}

#priceLow {
  border: none;
  background: transparent;
  font-weight: 500;
  color: #3d3c42 !important;
  font-size: 14px;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.form-label {
  font-weight: 500;
  margin: 0;
  color: #2b2b2b;
  text-wrap: wrap !important;
}

.form-control {
  border-radius: 10px;
  margin-bottom: 10px;
}

th.gridjs-th {
  color: #b5b7c0;
}

.gridjs-pagination .gridjs-summary {
  float: left;
  margin-top: 5px;
  color: #b5b7c0;
  font-weight: 500;
}

td.gridjs-td {
  border: none;
  border-bottom: 1px solid #eeeeee;
  font-size: 15px;
  color: #292d32;
  width: auto;
  text-align: center !important;
}

th.gridjs-th {
  border: none;
}

.gridjs-wrapper {
  box-shadow: none;
}

th.gridjs-th .gridjs-th-content {
  font-weight: 500;
}

.gridjs-footer {
  box-shadow: none;
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: #f7f7f7;
  font-weight: 500;
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: #2d4b64;
  font-weight: 500;
  color: #fff;
}

/* for modal */

.modal-body::-webkit-scrollbar {
  width: 12px;
}

.modal-body::-webkit-scrollbar-track {
  background: #e7e7e7;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: #707076;
  border-radius: 20px;
  border: 3px solid #e7e7e7;
}

/* end */

@media screen and (max-width: 768px) {
  .usersearch {
    position: relative;
    bottom: 40px;
    color: #627b87;
  }

  #userSearch {
    margin-bottom: 7px;
    width: 100%;
  }
}

/*=========================== User View ==================================*/

#viewUser .card {
  background-color: #fff;
}

/* DASHBOARD ADMIN CSS file style START*/

body {
  background-color: #fff;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}

.logoHeading {
  color: #fff;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.profile-sidebar {
  width: 100%;
  height: 100% !important;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  overflow-y: auto;
}

.sidebar-links {
  width: 100%;
  /* height: 100vh ; */
  overflow-y: auto;
}

.sidebar-links::-webkit-scrollbar {
  width: 12px;
}

.sidebar-links::-webkit-scrollbar-track {
  background: #2d4b64;
}

.sidebar-links::-webkit-scrollbar-thumb {
  background-color: #707076;
  border-radius: 20px;
  border: 3px solid #707076;
}

.sidebar-links a {
  color: #fff;
  width: 100%;
  display: flex;
  align-items: left;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  padding: 10px;
  /* margin-bottom: 15px; */
}

.sidebar-links .divSideBar {
  color: #fff;
  width: 100%;
  align-items: left;
  text-decoration: none;
  font-weight: 500;
  font-size: 17px;
  padding: 15px 10px;
  float: left;
}

.divSideBar div:first-child {
  float: left;
  margin-bottom: 5px !important;
  cursor: pointer;
}

.sidebar-links a.active {
  width: 100%;
  display: flex;
  align-items: left;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
}

.activeSideBar {
  background-color: #2d4b64;
  color: white !important;
  padding: 8px 0px 8px 5px;
}

.sidebar-links a img {
  width: 20px;
  margin-right: 10px;
}

.profileImg {
  width: 100px;
  border-radius: 100%;
  display: block;
  margin-right: auto;
}

.table>:not(caption)>*>* {
  font-weight: 400 !important;
}

.modal-body {
  max-height: 550px;
  overflow-y: auto;
}

.card-height {
  height: 250px;
  overflow: auto;
  padding: 12px;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

/* .card {
    background: linear-gradient(
      5deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 29%,
      rgba(0, 212, 255, 1) 100%
    ) !important;
  
    border-radius: 0px;
    border: none;
  } */

.sidebar-links a:hover,
.sidebar-links a:focus {
  color: #fff !important;
  background-color: #2d4b64;
}

.sidebar-links {
  width: 100%;
  /* height: 100vh; */
}

.sidebar-links-container::-webkit-scrollbar {
  width: 10px;
}

.sidebar-links-container::-webkit-scrollbar-track {
  background: #707076;
  /* background: linear-gradient(5deg,
      rgb(156, 223, 232) 0%,
      rgb(115, 150, 194) 29%,
      rgb(47, 76, 82) 100%) !important; */
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  /* position: unset !important; */
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  /* position: unset !important; */
}

.DropDownIconsInputField {
  position: absolute;
  z-index: 1;
  top: 34px;
  right: 18px;
}

.kBIVXf div {
  width: 100% !important;
}

.kBIVXf {
  min-width: 118px !important;
}

.PasswordField {
  padding-right: 39px;
}

.iseLcn {
  background-color: #707076 !important;
}

.kSDYzA {
  color: white;
  font-size: 16px;
}

.sidebar-links-container::-webkit-scrollbar-thumb {
  background-color: #2d4b64;
  border-radius: 10px;
  border: 1px solid #2d4b64;
}

.ImageInChat {
  object-fit: fill !important;
}

.btn-white {
  background-color: #fff !important;
  border: #fff !important;
  color: #1a1d1f;
  font-weight: 500;
  padding: 5px 35px;
  display: block;
  margin: auto;
  margin-top: 15% !important;
}

.sidebar-links-container {
  overflow-y: auto;
  max-height: 85vh;
}

#dashRIght {
  width: 80%;
}

#SideBArWid {
  max-height: 100% !important;
  min-height: 100vh;
  width: 20%;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #2d4b64;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: #fff;
}

.menu-bar {
  width: 100%;
  height: 2px;
  background-color: #000;
  margin: 3px 0;
  transition: all 0.3s ease;
}

.sidebar {
  width: 20% !important;
  /* min-width: 450px !important; */

  height: 100vh;
  position: fixed;
  top: 0;
  /* left: -250px; */
  background-color: #707076 !important;
  /* background-color: #2d4b64; */
  /* background: linear-gradient(
      5deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(9, 9, 121, 1) 29%,
      rgba(0, 212, 255, 1) 100%
    ) !important; */
  transition: left 0.3s ease;
  z-index: 99999999;
}

.sidebar.show {
  left: 0;
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
  #SideBArWid {
    height: 100vh;
    width: 20%;
  }

  #dashRIght {
    height: 100vh;
    width: 80%;
  }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
  #SideBArWid {
    height: 100vh;
    width: 100%;
  }

  #dashRIght {
    height: 100vh;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .mobile_logo_hide {
    display: none;
  }

  .sidebar-links {
    width: 100%;
    /* / height: 400px !important; / */
    overflow-y: auto;
  }

  #SideBArWid {
    height: 100vh;
    width: 100%;
  }

  #dashRIght {
    height: 100vh;
    width: 100%;
  }

  #SideBArWid {
    height: auto !important;
    max-height: auto !important;
    min-height: auto !important;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .logo {
    display: none;
  }

  .sidebar {
    left: 0;
    width: 100%;
  }

  /* 
    .sidebar.show {
      left: 0;
      width: 100% !important;
    } */
}

/* Benchmark file style START*/
.editbench {
  color: #2d4b64 !important;
  justify-content: end;
  align-items: end;
}

.editbench:hover {
  color: #2d4b64 !important;
  justify-content: end;
  align-items: end;
}

/* ADMIN CSS  file style  ======================================================END*/

/* COMPANY CSS file style ==================================================== START*/

/* SETTINGS file style START*/
.form-control:focus {
  box-shadow: none !important;
  border-color: #2d4b64;
}

/* MAIN file style START*/
.selectBorder .form-select {
  margin-bottom: 10px;
  border: 2px solid #2d4b64;
}

.form-select {
  margin-bottom: 10px;
}

.reviewaccount {
  background-color: #2d4b64;
  border: #2d4b64;
  color: #fff !important;
  border-radius: 50px;
  padding: 10px 10px;
}

.editable-text {
  background: none;
  border: none;
  outline: none;
  white-space: pre-wrap;
  /* or white-space: pre-line; */
}

.custom-modal {
  max-width: 600px;
}

.rdt_TableHeader {
  display: none !important;
}

.outerWrapperSearchIcon {
  position: absolute;
  top: 41px;
  left: 2px;
}

/* SUBSCRIPTION/SHOP file style START*/
#sub_card .btn-sub {
  background: linear-gradient(5deg,
      #2d4b64 0%,
      #4a6d89 29%,
      #2d4b64 100%) !important;
  /* background-color: #2d4b64 !important; */
  border: 1px solid #2d4b64 !important;
  color: #fff !important;
  text-align: center;

  border-radius: 100px;
  font-size: 13px;
  /* position: absolute; */
  bottom: 10px;
}

.sub-modal {
  max-width: 800px;
}

.btn-sub:hover {
  background: linear-gradient(5deg,
      #2d4b64 0%,
      #4a6d89 29%,
      #2d4b64 100%) !important;
  /* background-color: #2d4b64; */
  border: 1px solid #2d4b64;
  color: #fff;
  text-align: center;
  display: block;
  margin: auto;
  border-radius: 100px;
  /* margin-top: 35px; */
}

.btn-gradient {
  background: linear-gradient(5deg,
      #2d4b64 0%,
      #4a6d89 29%,
      #2d4b64 100%) !important;
}

.sub-cards h5 {
  font-size: 20px;
  padding: 5px 0px;
  font-weight: 500;
}

.sub-cards small {
  font-size: 15px;
}

.sub-cards h6 {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}

.btn-subwhite {
  background-color: #fff !important;
  border: 1px solid #fff !important;
  color: #2d4b64 !important;
  text-align: center;
  display: block;
  margin: auto;
  border-radius: 100px;
  font-size: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: -5px;
}

.subSmall {
  /* padding-top: 2px; */
  font-size: 12px;
  margin-bottom: 0;
  text-align: center;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid #ccc;
}

/* Style the table headers */
th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 10px;
}

/* Style the table data cells */
td {
  padding: 10px;
}

/* Add media query for responsiveness */
@media (max-width: 600px) {

  /* Hide table headers on small screens */
  table thead {
    text-align: center;
  }

  /* Center-align table data on small screens */
  table tbody td {
    text-align: center;
  }
}

/* SUPPORT file style START*/
.message-area {
  height: 100vh;
  overflow: hidden;
  padding: 30px 0;
  background: #f5f5f5;
}

.bg-white {
  background-color: #f1f2f7 !important;
  /* height: 100vh; */
}

.chat-area {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: 0.3rem;
  height: 90vh;
  overflow: hidden;
  min-height: calc(100% - 1rem);
}

.chatlist {
  outline: 0;
  height: 100%;
  overflow: hidden;
  padding-bottom: 10px !important;
}

.chat-area .modal-content {
  border: none;
  border-radius: 0;
  outline: 0;
  height: 100%;
}

.chat-area .modal-dialog-scrollable {
  height: 100% !important;
}

.chatbox {
  width: auto;
  overflow: hidden;
  height: 100%;
}

.chatbox .modal-dialog,
.chatlist .modal-dialog {
  max-width: 550px;
  margin: 0;
}

.msg-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-area .form-control {
  display: block;
  width: 80%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #222;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chat-area .form-control:focus {
  outline: 0;
  box-shadow: inherit;
}

a.add img {
  height: 36px;
}

.chat-area .nav-tabs {
  border-bottom: 1px solid #dee2e6;
  align-items: center;
  justify-content: space-between;
  flex-wrap: inherit;
}

.chat-area .nav-tabs .nav-item {
  width: 100%;
}

.chat-area .nav-tabs .nav-link {
  width: 100%;
  color: #262626;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-top: 5px;
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.chat-area .nav-tabs .nav-item.show .nav-link,
.chat-area .nav-tabs .nav-link.active {
  color: #222;
  background-color: #fff;
  border-color: transparent transparent #000;
}

.chat-area .nav-tabs .nav-link:focus,
.chat-area .nav-tabs .nav-link:hover {
  border-color: transparent transparent #000;
  isolation: isolate;
}

.msg-search h4 {
  color: #1e293b;
}

.chat-list h3 {
  color: #334155;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.chat-list p {
  color: #94a3b8;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.chat-list a.d-flex {
  margin-bottom: 5px;
  position: relative;
  text-decoration: none;
}

.chat-list .active {
  display: block;
  content: "";
  clear: both;
  position: absolute;
  bottom: 3px;
  left: 34px;
  height: 12px;
  width: 12px;
  background: #00db75;
  border-radius: 50%;
  border: 2px solid #fff;
}

.msg-head h3 {
  color: #1e293b;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}

.msg-head p {
  color: #343434;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}

.moreoption {
  display: flex;
  align-items: center;
  justify-content: end;
}

.moreoption .navbar {
  padding: 0;
}

.moreoption li .nav-link {
  color: #222;
  font-size: 16px;
}

.moreoption .dropdown-toggle::after {
  display: none;
}

.msg-body ul {
  overflow: hidden;
}

.msg-body ul li {
  list-style: none;
  margin: 5px 0;
}

.msg-body ul li.sender {
  display: block;
  width: 100%;
  position: relative;
}

.msg-body ul li.sender:before {
  display: block;
  clear: both;
  content: "";
  position: absolute;
  top: -6px;
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 15px 12px;
  border-color: transparent transparent #c9baba transparent;
  -webkit-transform: rotate(-37deg);
  -ms-transform: rotate(-37deg);
  transform: rotate(-37deg);
}

.msg-body ul li.sender p {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: #e7e6e6;
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 0;
}

.msg-body ul li.sender p b {
  display: block;
  color: #180660;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.msg-body ul li.repaly {
  display: block;
  width: 100%;
  text-align: right;
  position: relative;
}

.msg-body ul li.repaly p {
  color: #000;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 15px;
  background: #fff;
  display: inline-block;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-bottom: 0;
  position: absolute;
  right: 55px;
  top: -6px;
}

.msg-body ul li.repaly p b {
  display: block;
  color: #2d4b64;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.msg-body ul li.repaly:after {
  display: block;
  content: "";
  clear: both;
}

.time {
  display: block;
  color: #94a3b8;
  font-size: 12px;
  line-height: 2.5;
  font-weight: 400;
  margin-left: 5px;
}

li.repaly .time {
  margin-right: 5px;
}

.divider {
  position: relative;
  z-index: 1;
  text-align: center;
}

.msg-body h6 {
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: #222;
  background: #fff;
  display: inline-block;
  padding: 3px 11px;
  border-radius: 6px;
  margin-bottom: 0;
}

.bRMnmL {
  display: none !important;
}

.ewZTBs {
  font-weight: 400 !important;
}

.bEECzA {
  color: white !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.divider:after {
  display: block;
  content: "";
  clear: both;
  position: absolute;
  top: 12px;
  left: 0;
  border-top: 1px solid #d4d4d4;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.send-box {
  padding: 10px 10px;
}

.send-box form {
  align-items: center;
}

.section-to-print {
  padding: 25px 0px;
}

.vertical-text {
  writing-mode: vertical-rl !important;
  /* rl stands for "right-to-left" */
  white-space: nowrap !important;
}

.deleteButton:hover .whiteShadow {
  background: #000 !important;
}

.backHover {
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
}

.send-box .form-control {
  display: block;
  width: 100%;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #222;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 10px;
}

.sendicon {
  display: block;
  color: #94a3b8;
}

.send-btns .button-wrapper {
  position: relative;
  width: 125px;
  height: auto;
  text-align: left;
  margin: 0 auto;
  display: block;
  background: #f6f7fa;
  border-radius: 3px;
  padding: 5px 15px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  overflow: hidden;
}

.btn-send {
  position: relative;
  right: 6%;
}

.send-btns .button-wrapper span.label {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: #343945;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 13px;
}

#upload {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.send-btns .attach .form-control {
  display: inline-block;
  width: 120px;
  height: auto;
  padding: 5px 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #343945;
  background-color: #f6f7fa;
  background-clip: padding-box;
  border: 1px solid #f6f7fa;
  border-radius: 3px;
  margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
  margin-right: 5px;
}

.button-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 0 auto;
}

button:focus {
  outline: 0;
}

.add-apoint {
  display: inline-block;
  margin-left: 5px;
}

.add-apoint a {
  text-decoration: none;
  background: #f6f7fa;
  border-radius: 8px;
  padding: 8px 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  color: #343945;
}

.add-apoint a svg {
  margin-right: 5px;
}

.chat-icon {
  display: none;
}

.closess i {
  display: none;
}

.ActiveChathover {
  background-color: #8e8e9456;
}

.chathover:hover {
  background-color: #8e8e9456;
}

.btn-dash {
  color: #000 !important;
  border: 1px solid #000 !important;
}

.selected {
  background-color: #2d4b64 !important;
  color: white !important;
  border: 1px solid #2d4b64 !important;
}

.unSelected {
  color: #2d4b64 !important;
  border: 1px solid #2d4b64 !important;
}

.unSelected:hover {
  background-color: #2d4b64 !important;
  color: white !important;
  border: 1px solid #2d4b64 !important;
}

textarea {
  resize: none;
}

.OverFlow {
  overflow: auto !important;
}

@media (max-width: 992px) {
  .btn-send {
    position: relative;
    right: 13%;
  }
}

@media (max-width: 767px) {
  .chat-icon {
    display: block;
    margin-right: 5px;
  }

  .chatlist {
    width: 100%;
  }

  .chatbox {
    width: 100%;
    right: 0;
    background: #fff;
    transition: all 0.5s ease;
    border-left: none;
  }



  .showbox {
    left: 0 !important;
    transition: all 0.5s ease;
  }

  .msg-head h3 {
    font-size: 14px;
  }

  .msg-head p {
    font-size: 12px;
  }

  .msg-head .flex-shrink-0 img {
    height: 30px;
  }

  .btn-send {
    position: relative;
    right: 12%;
  }

  .send-box .form-control {
    width: 100%;
  }

  .chat-list h3 {
    font-size: 14px;
  }

  .chat-list p {
    font-size: 12px;
  }

  .msg-body ul li.sender p {
    font-size: 13px;
    padding: 8px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .msg-body ul li.repaly p {
    font-size: 13px;
    padding: 8px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

/* DATATOUCHPOINT file style START*/
.btnPrint {
  color: #2d4b64;
  font-size: 20px;
  margin: 0;
}

.threeOut {
  border: 1px solid #2d4b64;
  padding: 12px 12px 7px 12px;
  border-radius: 5px;
  color: #2d4b64;
  font-size: 17px;
  font-weight: 500;
}

.getMore {
  color: #fff !important;
  font-size: 16px;
  padding: 9px 20px;
  background: #1f8aca !important;
  font-weight: 500;
  border-radius: 5px;
  margin-bottom: 9px;
  cursor: pointer;
}

.loader {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #1f8aca;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

/* COMPANY CSS file style ==================================================== END*/

.bOrxbQ {
  font-weight: 500 !important;
  font-size: 14px !important;
}

@media screen and (max-width: 767px) {
  .sidebar {
    width: 0% !important;
  }

  .mbl-none {
    display: none !important;
  }

  .mbl-graph {
    display: block !important;
  }

  .sidebar.show {
    left: 0;
    width: 75% !important;
  }
}

@media screen and (min-width: 767px) {
  .mbl-graph {
    display: flex !important;
    justify-content: space-between;
  }
}

/* THANKS YOU PAGE  */

.wrapper-2 {
  padding: 30px;
  text-align: center;
  box-shadow: 4px 8px 10px 8px rgba(88, 146, 255, 0.2);
}

.wrapper-2 h1 {
  font-size: 3em !important;
  letter-spacing: 3px;
  color: #2d4b64;
  margin: 0;

  margin-bottom: 20px;
}

.wrapper-2 p {
  margin: 0;
  font-size: 1.3em;
  color: #aaa;
  letter-spacing: 1px;
}

@media (max-width: 360px) {
  .wrapper-2 h1 {
    font-size: 2.5em !important;
  }
}

@media (min-width: 600px) {
  .wrapper-1 {
    height: initial;
    max-width: 620px;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 4px 8px 10px 8px rgba(88, 146, 255, 0.2);
  }
}

@media print {

  /* Reset margins and padding for better print layout */
  body {
    margin: 0;
    padding: 10px;
  }

  /* Define styles for the printed content */
  .section-to-print {
    position: fixed;
    width: 100%;
    height: 100%;
  }

  /* Add more print-specific styles as needed */
}

@media screen and (max-width: 320px) {
  .btn-primary {
    font-size: 13px !important;
    width: 100% !important;
  }
}

@media (max-width: 599px) {
  .outerWrapperSearchBox {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .btn-chart {
    padding: 0px 9px;
    font-size: 11px;
  }
}